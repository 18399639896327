import React, { useState,useEffect } from 'react'
import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route} from "react-router-dom";   
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Contact from './pages/Contact';

function App() {

  useEffect(() => {
    document.title = 'PT Hattori Indonesia - part of Takeshi Hattori Co. Ltd';
    
  }, []);

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
