import React from "react";

function HeroText({title}) {

    return(
    <>
       <div class="bg-orange text-white">
            <div class="section-padding text-center">
                <h1 class="display-4 fw-bold">{title}</h1>
                <div class="divider-hero"></div>
            </div>
        </div>
    </>
    )
}


export default HeroText;


