import React, { useState } from 'react'
import HeroText from '../components/HeroText';

function Contact() {
    const [loading, setLoading] = useState(false);
    return (
        <>
            <HeroText 
            title={"Kontak Kami"}/>

            <div class="">
                <div class="row">
                    <div class="col-lg-6 col-12 text-center" style={{padding:'10%'}}>
                        {/* <img src="/images/logo/logo-hattori-ht.png"  style={{height:'78px',width:'auto'}} /> */}
                        <img src="/images/logo/logo-hattori-indo.png" style={{maxHeight:'48px',marginBottom:'16px'}}/>
                        <p class="lead mb-4">Jl.Raya Majalaya Rancaekek No.389, Solokanjeruk, Kec.Solokanjeruk, Kabupaten Bandung, Jawa Barat 45364</p>
                        <p class="lead">
                        Email: <span style={{fontWeight:'600'}}>hattori.indonesia@gmail.com</span>
                        <br/>
                        Telepon: <span style={{fontWeight:'600'}}>(022)5954325</span>
                        </p>
                    </div>
                    <div class="col-lg-6 col-12" style={{padding:'10% 5%'}}>
                    <div className="google-map-code">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.0381082596896!2d107.74223371528845!3d-7.0047955949400205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68c456565fc563%3A0x4cd013c93c813ef7!2sHattori%20Indonesia.%20PT!5e0!3m2!1sid!2sid!4v1668397360114!5m2!1sid!2sid" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                    </div>
                    
                    </div>
                </div>
            </div>

        </>
    )
}

export default Contact
