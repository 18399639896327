import React, { useState } from 'react'
import HeroText from '../components/HeroText';

function Products() {
    const [loading, setLoading] = useState(false);
    return (
        <>
       <HeroText
        title={"Produk Kami"}
        />


        <div className="section-padding">
            <div className="container">
                <div className="title-pannel text-center">

                    <p className="lead">Kami terus menerus meningkatkan kualitas dan produksi perusahaan serta produk kami pada saat meningkatnya perekonomian Jepang yang cukup Signifikan dan melalu revolusi industri. Berikut ini adalah apa yang kami kerjakan pada PT. Hattori Indonesia.</p>
                </div>
                <div className="px-lg-0 px-3">
                   
                    <div className="d-flex align-items-center bg-orange content-product-margin" id="weaving">
                        <div className="col-lg-5 col-12 p-0">
                            {/* FIXME : dibuat slide*/}
                            {/* <img className="img-fluid" src="/images/services/products001.png"/> */}
                            <div id="carouselExampleIndicators" class="carousel slide " data-bs-ride="carousel">
                                <div class="carousel-indicators" style={{zIndex:'99'}}>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                                </div>
                                <div class="carousel-inner w-100">
                                    <div class="carousel-item active w-100">
                                        <img src="/images/gallery/weaving_1.png" class="w-100 img-fluid "  alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/gallery/weaving_2.png" class="w-100 img-fluid" alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/gallery/weaving_3.png" class="w-100 img-fluid" alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/gallery/weaving_4.png" class="w-100 img-fluid" alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/gallery/weaving_5.png" class="w-100 img-fluid" alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/gallery/weaving_6.png" class="w-100 img-fluid" alt="..."/>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" style={{zIndex:'99'}}>
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" style={{zIndex:'99'}}>
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 p-0">
                            <div className="px-4">
                            <div className='d-flex flex-wrap align-items-center gap-3 mb-4'>
                                {/* <img className="img-product-icon" src="/images/services/001-white.png"/> */}
                                <h4 className="text-white fw-bold">Weaving</h4>
                            </div>
                            <p className="text-second">PT. Hattori Indonesia bertempat di Solokanjeruk yang notabene berada di dekat Kawasan Majalaya, dimana Majalaya sendiri terkenal sebagai kota tekstil yang tidak hanya di Indonesia, namun diakui pula oleh dunia Internasional. Unit Weaving PT. Hattori menghasilkan kain tenunan mentah atau biasa kita sebut Kain <i>Greige</i>. Kain yang kita produksi mempunyai keragaman jenis dan fungsi, dari mulai bahan pelapis kain jok sampai dengan bahan untuk kain amplas.</p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center bg-orange content-product-margin flex-row-reverse" id="knitting">
                        <div className="col-lg-5 col-12 p-0">
                            {/* <img className="img-fluid" src="/images/services/products002.png"/> */}
                            <div id="carouselProduct2" class="carousel slide " data-bs-ride="carousel">
                                <div class="carousel-indicators" style={{zIndex:'99'}}>
                                    <button type="button" data-bs-target="#carouselProduct2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselProduct2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselProduct2" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                </div>
                                <div class="carousel-inner w-100">
                                    <div class="carousel-item active w-100">
                                        <img src="/images/gallery/knitting_01.jpg" class="w-100 img-fluid "  alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/gallery/knitting_02.jpg" class="w-100 img-fluid" alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/gallery/knitting_03.jpg" class="w-100 img-fluid" alt="..."/>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselProduct2" data-bs-slide="prev" style={{zIndex:'99'}}>
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselProduct2" data-bs-slide="next" style={{zIndex:'99'}}>
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 p-0 text-end">
                            <div className="px-4">
                            <div className='d-flex flex-wrap align-items-center gap-3 mb-4 justify-content-end'>
                                {/* <img className="img-product-icon" src="/images/services/002-white.png"/> */}
                                <h4 className="text-white fw-bold">Knitting</h4>
                            </div>
                            <p className="text-second">Unit Knitting PT. Hattori Indonesia memproduksi berbagai kain rajutan mentah, sehingga Unit Knitting kami dapat disebut sebagai salah satu penghasil Kain Greige di PT. Hattori Indonesia. Unit Knitting kami dapat memproduksi Kain Greige sebanyak 1.200 ton/tahun, hasil produksi kami tersebut biasa digunakan sebagai salah satu bahan baku pendukung industri lain nya <i>(material raw)</i>. Kain Greige kami pasarkan secara ekspor ke berbagai Negara seperti Jepang dan Thailand.</p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center bg-orange content-product-margin" id="garment">
                        <div className="col-lg-5 col-12 p-0">
                            {/* <img className="img-fluid" src="/images/services/products003.png"/> */}
                            <div id="carouselProduct3" class="carousel slide " data-bs-ride="carousel">
                                <div class="carousel-indicators" style={{zIndex:'99'}}>
                                    <button type="button" data-bs-target="#carouselProduct3" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselProduct3" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                </div>
                                <div class="carousel-inner w-100">
                                    <div class="carousel-item active w-100">
                                        <img src="/images/gallery/garment_1.png" class="w-100 img-fluid "  alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/gallery/garment_2.png" class="w-100 img-fluid" alt="..."/>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselProduct3" data-bs-slide="prev" style={{zIndex:'99'}}>
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselProduct3" data-bs-slide="next" style={{zIndex:'99'}}>
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 p-0">
                            <div className="px-4">
                            <div className='d-flex flex-wrap align-items-center gap-3 mb-4'>
                                {/* <img className="img-product-icon" src="/images/services/003-white.png"/> */}
                                <h4 className="text-white fw-bold">Garment</h4>
                            </div>
                            <p className="text-second">Departemen Garment kami didukung oleh 84 unit mesin jahit otomatis dan mesin obras :
                                <br/>*merk mesin jahit & tipe* Brother xxx
                                <br/>*merk mesin obras & tipe* Pegasus xxx
                            </p>
                            <p className="text-second">Unit Garment PT. Hattori Indonesia senantiasa berusaha menjadi Garment terbaik yang dapat diandalkan oleh <i>Cutomer</i> & Calon <i>Customer</i> kami, dengan mengusung slogan <i>“One Stop Solution”</i> kami dapat menyediakan berbagai macam kebutuhan produk Garment. Kami selalu berusaha konsisten dan dapat diandalkan, pelayanan terbaik kami berbasi customer satisfaction untuk menyediakan produk garment dengan kualitas terbaik, kontrol yang ketat dan harga yang kompetitif. 
Produk yang kami buat adalah beragam Baju Festival Jepang beserta Aksesoris Pakaian dan Kain Napkin, tidak menutup kemungkinan kami akan membuat produk lainnya sesuai dengan permintaan dan kebutuhan <i>Customer</i> kami..</p>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-items-center bg-orange content-product-margin flex-row-reverse" id="buffing">
                        <div className="col-lg-5 col-12 p-0">
                            {/* <img className="img-fluid" src="/images/services/products004.png"/> */}
                            <div id="carouselProduct4" class="carousel slide " data-bs-ride="carousel">
                                <div class="carousel-indicators" style={{zIndex:'99'}}>
                                    <button type="button" data-bs-target="#carouselProduct4" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselProduct4" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                </div>
                                <div class="carousel-inner w-100">
                                    <div class="carousel-item active w-100">
                                        <img src="/images/gallery/buff_1.png" class="w-100 img-fluid "  alt="..."/>
                                    </div>
                                    <div class="carousel-item">
                                        <img src="/images/gallery/buff_2.png" class="w-100 img-fluid" alt="..."/>
                                    </div>
                                </div>
                                <button class="carousel-control-prev" type="button" data-bs-target="#carouselProduct4" data-bs-slide="prev" style={{zIndex:'99'}}>
                                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Previous</span>
                                </button>
                                <button class="carousel-control-next" type="button" data-bs-target="#carouselProduct4" data-bs-slide="next" style={{zIndex:'99'}}>
                                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span class="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-7 col-12 p-0 text-end">
                            <div className="px-4">
                            <div className='d-flex flex-wrap align-items-center gap-3 mb-4 justify-content-end'>
                                {/* <img className="img-product-icon" src="/images/services/002-white.png"/> */}
                                <h4 className="text-white fw-bold">Buffing</h4>
                            </div>
                            <p className="text-second">PT. Hattori Indonesia dapat memproduksi alat <i>Buff</i> yaitu pemoles untuk kebutuhan industri. Segmentasi pemasaran produk kami adalah industri yang mempunyai salah satu rangkain produksi pemolesan, seperti memoles rangka baja maupun kayu untuk berbagai alat musik yang terbuat dari kayu. Unit Buff PT. Hattori Indonesia memproduksi alat <i>Buff</i> kurang lebih 16.000 pcs/bulan, alat <i>Buff</i> tersebut salah satu bahan bakunya berupa metal yang secara khusus kami datangkan dari Jepang, hal tersebut kami lakukan agar kualitas kami dapat tetap terjaga dan senantiasa dapat memenuhi kebutuhan spesifikasi bagi konsumen kami. Alat Buff tersebut kami pasarkan secara lokal maupun internasional.</p>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
            
        </div>
        </>
    )
}

export default Products
