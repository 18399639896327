import React, { useState } from 'react'
import HeroText from '../components/HeroText';

function About() {
    const [loading, setLoading] = useState(false);
    return (
        <>
        <HeroText
            title={'Tentang Kami'}
            />

        <div class="">
            <div class="container">
                <div class="row content-about-first">
                    <div class="col-12 mb-4" >
                        <h2 class="display-6 fw-bold lh-1">Ucapan salam dari Komisaris</h2>
                    </div>
                    <div class="col-12">
                        <p className='mb-4'>
                        Perushaan kami dididirikan sebagai penjual grosir kain katun pada tahun 1946 oleh pendiri kami, Takeshi Hattori, setelah perang.
                        </p>
                        <p className='mb-4'>
                        Sejak saat itu, kami telah meningkatkan kualitas dan produksi perusahaan serta produk kami pada saat meningkatknya perekonomian Jepang yang signifikan dan melalui revolusi industri. Dan kami mampu mencapai tahap ini tanpa insiden signifikan saat perjalanan kami perubahan menjadi perusahaan perdagangan bahan fiber.
                        </p>
                        <p className='mb-4'>
                        Ini dikarenakan alasan kebaikan dan keramahan rekan bisnis kami dari tahun ke tahun dan dengan rasa terima kasih dan syukur kami kepada pegawai kami.
                        </p>
                        <p className='mb-4'>
                        Kami percaya bahwa ini adalah misi kami untuk mencari tujuan dari pelanggan dan masyarakat butuhkan. Selalu melihat dengan teliti struktur industri dan kondisi ekonomi pada waktu yang terus berubah.
                        </p>
                        <p className='mb-4'>
                        Pada masa yang akan datang kami berharap untuk selalu menawarkan jasa kami kepada lebih banyak orang dan tidak berhenti berinovasi. Kami berterima kasih buat arahan tak terhingga dan kepercayaan anda.
                        </p>
                    </div>
                </div>

                <div class="row content-about-second">
                    <div class="col-12 mb-4" >
                        <h2 class="display-6 fw-bold lh-1">Manufaktur kain berstandar Jepang yang mengedepankan kualitas produk berbahan dasar katun & fiber</h2>
                    </div>
                    <div class="col-12">
                        <p className='mb-4'>
                        PT Hattori Indonesia merupakan anak perusahaan Takeshi Hattori Co., Ltd.,. PT Hattori Indonesia bergerak pada bidang produsen skala besar serta manufaktur kain kain yang memiliki fungsi komersial dan domestik dengan standar jepang terdepan yang terjaga kualitasnya pada membuatnya. Business utama kami bergerak pada bidang pembuatan dan penjualan produk berbahan dasar katun dan fiber serat sintetis serta import export dari produk bahan tersebut.
                        </p>
                        <p className='mb-4'>
                        Kantor dan pabrik PT Hattori Indonesia berada 34 km dari pusat kota bandung. PT Hattori Indonesia menjunjung tinggi permintaan pasar yang selalu menjadi lebih beragam dan mengalami diversifikasi segment dengan berbagai sumber dan penggunaan pada lapangan.
                        </p>
                        <p className='mb-4'>
                        PT Hattori Indonesia menawarkan produk dengan kegunaan dalam berbagai bidang seperti bahan agrikultural. Bahan dupont tyvek untuk banner serta bahan pembangunan mentah dan bahan baku pekerjaan konstruksi. Kami ,menjaga kualitas dan resiliensi dari produk kami guna memenuhi standar dari pengguna kami terutama pada lingkungan kerja yang ekstrim dengan suhu yang fluktuatif serta terpaan material asing kepada permukaan bahan bahan kami.
                        </p>
                        <p className='mb-4'>
                        PT Hattori Indonesia menjunjung tinggi nilai pengembangan bisnis yang bisa masuk dan bekerjasama dengan semua bidang pekerjaan dan industri. Dengan kegunaan yang lingkup luas kami selalu melihat dari keingginan dan permintaan klien kami. Penyediaan dari variasi barang dan bahan baku yang sesuai dengan permintaan dan kriteria kategori mereka.
                        </p>
                        <p className='mb-4'>
                        PT Hattori Indonesia merupakan salah satu perusahaan asing tertua di Indonesia serta salah satu perusahaan produsen kain dan material mentah terpercaya di indonesia. PT Hattori Indonesia menyediakan bahan bahan yang juga memiliki kegunaan pada lapangan medis, industri konstruksi, otomotif dan kebutuhan sehari hari seperti baju dan sarana prasarana domestik.
                        </p>
                    </div>
                </div>
            </div>
        </div>


        

        <div class="section-galery">
            <div class="text-center title-gallery-section">
                <h2 class="display-6 fw-bold lh-1">Galeri Hattori Indonesia</h2>
            </div>
            <div class="container">
            <div class="row">
                <div class="col-lg-6 col-12 p-2">
                    <img class="img-fluid" src="/images/gallery/hattori_gallery_1.png" alt=""/>
                </div>
                <div class="col-lg-6 col-12 p-2">
                    <img class="img-fluid" src="/images/gallery/hattori_gallery_2.png" alt=""/>
                </div>
                <div class="col-lg-6 col-12 p-2">
                    <img class="img-fluid" src="/images/gallery/hattori_gallery_3.png" alt=""/>
                </div>
                <div class="col-lg-6 col-12 p-2">
                    <img class="img-fluid" src="/images/gallery/hattori_gallery_4.png" alt=""/>
                </div>
                <div class="col-lg-6 col-12 p-2">
                    <img class="img-fluid" src="/images/gallery/hattori_gallery_5.png" alt=""/>
                </div>
                <div class="col-lg-6 col-12 p-2">
                    <img class="img-fluid" src="/images/gallery/hattori_gallery_6.png" alt=""/>
                </div>
                <div class="col-lg-6 col-12 p-2">
                    <img class="img-fluid" src="/images/gallery/hattori_gallery_7.png" alt=""/>
                </div>
                <div class="col-lg-6 col-12 p-2">
                    <img class="img-fluid" src="/images/gallery/hattori_gallery_8.png" alt=""/>
                </div>

            </div>
            </div>
        </div>
        <div class="section-padding">
            <div class="container">
            <div class="card bg-cream card-services">
                <div class="card-body p-4 ">
                    <div class="d-flex align-items-center gap-5">
                        <div class="text-center p-4">
                            <img className="img-services" src="/images/services/001.png" alt=""/>
                            <h3 class="fw-bold lh-1 mt-3">weaving</h3>
                        </div>
                        <div className='panel-content-services-first'>
                            <p class="leads">
                            PT. Hattori Indonesia bertempat di Solokanjeruk yang notabene berada di dekat Kawasan Majalaya, dimana Majalaya sendiri terkenal sebagai kota tekstil yang tidak hanya di Indonesia, namun diakui pula oleh dunia Internasional. Unit Weaving PT. Hattori menghasilkan kain tenunan mentah atau biasa kita sebut Kain <i>Greige</i>. Kain yang kita produksi mempunyai keragaman jenis dan fungsi, dari mulai bahan pelapis kain jok sampai dengan bahan untuk kain amplas.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card bg-cream card-services" >
                <div class="card-body p-4 ">
                    <div class="d-flex align-items-center gap-5">
                        <div class="d-flex align-items-center panel-content-services">
                            <p class="leads ">
                            Unit Knitting PT. Hattori Indonesia memproduksi berbagai kain rajutan mentah, sehingga Unit Knitting kami dapat disebut sebagai salah satu penghasil Kain Greige di PT. Hattori Indonesia. Unit Knitting kami dapat memproduksi Kain Greige sebanyak 1.200 ton/tahun, hasil produksi kami tersebut biasa digunakan sebagai salah satu bahan baku pendukung industri lain nya <i>(material raw)</i>. Kain Greige kami pasarkan secara ekspor ke berbagai Negara seperti Jepang dan Thailand.
                            </p>
                        </div>
                        <div class="text-center p-4">
                            <img className="img-services" src="/images/services/002.png" alt=""/>
                            <h3 class="fw-bold lh-1 mt-3">knitting</h3>
                        </div>
                    </div> 
                </div>
            </div>

            <div class="card bg-cream card-services">
                <div class="card-body p-4 ">
                <div class="d-flex align-items-center gap-5">
                    <div class="text-center p-4">
                        <img className="img-services" src="/images/services/003.png" alt=""/>
                        <h3 class="fw-bold lh-1 mt-3">garmen</h3>
                    </div>
                    <div className='panel-content-services-first'>
                        <p class="leads">
                            Departemen Garment kami didukung oleh 84 unit mesin jahit otomatis dan mesin obras :
                            <br/>*merk mesin jahit & tipe* Brother xxx
                            <br/>*merk mesin obras & tipe* Pegasus xxx
                        </p>
                        <p class="leads">
                        Unit Garment PT. Hattori Indonesia senantiasa berusaha menjadi Garment terbaik yang dapat diandalkan oleh <i>Cutomer</i> & Calon <i>Customer</i> kami, dengan mengusung slogan <i>“One Stop Solution”</i> kami dapat menyediakan berbagai macam kebutuhan produk Garment. Kami selalu berusaha konsisten dan dapat diandalkan, pelayanan terbaik kami berbasi customer satisfaction untuk menyediakan produk garment dengan kualitas terbaik, kontrol yang ketat dan harga yang kompetitif. 
Produk yang kami buat adalah beragam Baju Festival Jepang beserta Aksesoris Pakaian dan Kain Napkin, tidak menutup kemungkinan kami akan membuat produk lainnya sesuai dengan permintaan dan kebutuhan <i>Customer</i> kami.
                        </p>
                    </div>
                </div>
                
                </div>
            </div>



            <div class="card bg-cream card-services">
                <div class="card-body p-4 ">
                    <div class="d-flex align-items-center gap-5">
                        <div class="d-flex align-items-center panel-content-services">
                            <p class="leads ">
                            PT. Hattori Indonesia dapat memproduksi alat <i>Buff</i> yaitu pemoles untuk kebutuhan industri. Segmentasi pemasaran produk kami adalah industri yang mempunyai salah satu rangkain produksi pemolesan, seperti memoles rangka baja maupun kayu untuk berbagai alat musik yang terbuat dari kayu. Unit Buff PT. Hattori Indonesia memproduksi alat <i>Buff</i> kurang lebih 16.000 pcs/bulan, alat <i>Buff</i> tersebut salah satu bahan bakunya berupa metal yang secara khusus kami datangkan dari Jepang, hal tersebut kami lakukan agar kualitas kami dapat tetap terjaga dan senantiasa dapat memenuhi kebutuhan spesifikasi bagi konsumen kami. Alat Buff tersebut kami pasarkan secara lokal maupun internasional.
                            </p>
                        </div>
                        <div class="text-center p-4">
                            <img className="img-services" src="/images/services/004.png" alt=""/>
                            <h3 class="fw-bold lh-1 mt-3 ">buffing</h3>
                        </div>
                    </div> 
                </div>
            </div>


            </div>
        </div>
        </>
    )
}

export default About
