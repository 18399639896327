import React, { useState } from 'react'

function Home() {
    const [loading, setLoading] = useState(false);
    const [quote, setQuote] = useState({ name: "", email: "",company:"",message:"" });
    const [quoteName, setQuoteName] = useState("");
    const [quoteEmail, setQuoteEmail] = useState("");
    const [quoteCompany, setQuoteCompany] = useState("");
    const [quoteMessage, setQuoteMessage] = useState("");

    const handleChange = e => {
        const { name, value } = e.target;
        switch (name) {
            case "quoteName":
                setQuoteName(value);
                break;
            case "quoteEmail":
                setQuoteEmail(value);
                break;
            case "quoteCompany":
                setQuoteCompany(value);
                break;
            case "quoteMessage":
                setQuoteMessage(value);
                break;
            default:
                break;
        }
        console.log(name,"-",value);
    };

    
    return (
        <>
        <div className="bg-header" style={{position:'relative'}}>
            <div id="carouselExampleIndicators" class="carousel slide " data-bs-ride="carousel">
                <div class="carousel-indicators" style={{zIndex:'99'}}>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div class="carousel-inner w-100">
                    <div class="carousel-item active w-100">
                        <img src="images/backgrounds/gallery-header-1.png" class="w-100 img-fluid "  alt="..."/>
                        <a href='#'>
                        <div class="carousel-caption d-none d-md-block" style={{zIndex:'99'}}>
                            <h2 style={{fontSize:'40px'}}>Hattori Indonesia</h2>
                            <p>Solusi kebutuhan bahan Kain & Material Industri dan Usaha Anda. Hattori menyediakan banyak pilihan kain dan bahan sintesis untuk kebutuhan proses manufaktur dan produksi alat industri.</p>
                        </div>
                        </a>
                    </div>
                    <div class="carousel-item">
                        <img src="images/backgrounds/gallery-header-3.png" class="w-100 img-fluid" alt="..."/>
                        <a href='#'>
                        <div class="carousel-caption d-none d-md-block" style={{zIndex:'99'}}>
                            <h2 style={{fontSize:'40px'}}>Hattori Indonesia</h2>
                            <p>Solusi kebutuhan bahan Kain & Material Industri dan Usaha Anda. Hattori menyediakan banyak pilihan kain dan bahan sintesis untuk kebutuhan proses manufaktur dan produksi alat industri.</p>
                        </div>
                        </a>
                    </div>
                    <div class="carousel-item">
                        <img src="images/backgrounds/header_4.png" class="w-100 img-fluid" alt="..."/>
                        <a href='#'>
                        <div class="carousel-caption d-none d-md-block" style={{zIndex:'99'}}>
                            <h2 style={{fontSize:'40px'}}>Hattori Indonesia</h2>
                            <p>Solusi kebutuhan bahan Kain & Material Industri dan Usaha Anda. Hattori menyediakan banyak pilihan kain dan bahan sintesis untuk kebutuhan proses manufaktur dan produksi alat industri.</p>
                        </div>
                        </a>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" style={{zIndex:'99'}}>
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" style={{zIndex:'99'}}>
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
            {/* <div className="vh-100 masking-brown">
                <div className="container vh-100">
                    <div className="d-flex align-items-center vh-100 text-white">
                        <div className="col-lg-8 col-12">
                        <h1 className="display-5 fw-bold lh-1 mb-4">Hattori <span className="text-orange">Indonesia</span></h1>
                        <h4 className="lead text-second">Solusi kebutuhan bahan Kain & Material Industri dan Usaha Anda. Hattori menyediakan banyak pilihan kain dan bahan sintesis untuk kebutuhan proses manufaktur dan produksi alat industri.</h4>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='masking-test' ></div>
        </div>

        <div className="">
            <div className="row">
            <div className="col-lg-6 col-12 bg-cream" style={{padding:'10%'}}>
                <h1 className="display-5 fw-bold lh-1 mb-3">Tentang Kami</h1>
                <p className="lead">PT Hattori Indonesia merupakan anak perusahaan Takeshi Hattori Co., Ltd.,. PT Hattori Indonesia bergerak pada bidang produsen skala besar serta manufaktur kain kain yang memiliki fungsi komersial dan domestik dengan standar jepang terdepan yang terjaga kualitasnya pada membuatnya. Business utama kami bergerak pada bidang pembuatan dan penjualan produk berbahan dasar katun dan fiber serat sintetis serta import export dari produk bahan tersebut.
</p>
                <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <a href="/about" className="link mt-4">Learn More <i className="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div className="col-lg-6 col-12 bg-orange text-white" style={{padding:'10%'}}>
                {/* <h1 className="display-5 fw-bold lh-1 mb-3">Lorem Ipsum</h1> */}
                <div className="row">
                <div className="col-6 my-4">
                    <h4>Sejak  Tahun</h4>
                    <h2 className="display-3 fw-bold">1996</h2>
                </div>
                <div className="col-6 my-4">
                    <h4>Produksi</h4>
                    <h2 className="display-3 fw-bold">567+</h2>
                </div>
                <div className="col-6 my-4">
                    <h4>Karyawan</h4>
                    <h2 className="display-3 fw-bold">314+</h2>
                </div>
                <div className="col-6 my-4">
                    <h4>Mitra Kami</h4>
                    <h2 className="display-3 fw-bold">27+</h2>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div className="section-padding">
            <div className="container">
            <div className="title-pannel text-center">
                <h1 className="display-5 fw-bold lh-1 mb-3">Produk Kami</h1>
                <p className="lead">Kami terus menerus meningkatkan kualitas dan produksi perusahaan serta produk kami pada saat meningkatnya perekonomian Jepang yang cukup Signifikan dan melalu revolusi industri. Berikut ini adalah apa yang kami kerjakan pada PT. Hattori Indonesia </p>
            </div>
            <div className="container">
                <div className="row text-center">
                    <div className="col-lg-6 col-12 px-4 mb-4">
                        <a href='#'>
                        <img className="img-fluid" src="/images/services/p1.jpg"/>
                        </a>
                        <div className="mt-3">
                        <a href="#"><h5 className="fw-bold">Weaving</h5></a>
                        <p className="text">Kami Memilih bahan baku dan proses produksi yang berkualitas, menghasilkan kain <i>greige</i> dengan kualitas ekspor.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 px-4 mb-4">
                        <a href='#'>
                        <img className="img-fluid" src="/images/services/p2.jpg"/>
                        </a>
                        <div className="mt-3">
                        <a href="#"><h5 className="fw-bold">Knitting</h5></a>
                        <p className="text">Dari bahan baku ini dapat menjadi bahan <i>Fabric</i> seperti kulit jok, sarung bantal, dan juga  menjadi penunjang bahan baku peralatan otomotif.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 px-4 mb-4">
                        <a href='#'>
                        <img className="img-fluid" src="/images/services/p3.jpg"/>
                        </a>
                        <div className="mt-3">
                        <a href="#"><h5 className="fw-bold">Garment</h5></a>
                        <p className="text">Bahan Garmen terseleksi dan diproses dengan cara berkualitas dengan <i>standard</i> ekspor yang <i>efficient</i> dan terautomasi</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 px-4 mb-4">
                        <a href='#'>
                        <img className="img-fluid" src="/images/services/p4.jpg"/>
                        </a>
                        <div className="mt-3">
                        <a href="#"><h5 className="fw-bold">Buffing</h5></a>
                        <p className="text"><i>Buffing</i> berfungsi untuk menghaluskan dan memoles permukaan, bahkan membuat produk kayu maupun metal menjadi lebih mengkilap.</p>
                        </div>
                    </div>
                    
                </div>
                <div className="text-center mt-4">
                <a href='/products'>
                <button type="button" className="btn btn-outline-primary btn-lg px-4">Selengkapnya</button>
                </a>
                </div>
            </div>
            </div>
            
        </div>

        <div className="bg-header" style={{display:'none'}}>
            <div className="masking-brown">
            <div className="section-padding text-white">
                <div className="container">
                <div className="title-pannel text-center">
                    <span style={{color: '#ddd'}}>WHY CHOOSE US</span>
                    <h1 className="display-5 fw-bold lh-1 mb-3 mt-2">What Sets Us Apart</h1>
                </div>
                <div className="">
                    <div className="row">
                    <div className="col-lg-4 col-12 px-4 mb-4">
                        <div className="card-dotted card-padding text-center text-white">
                        <img src="/images/logo/diamond.png"/>
                        <h5 className="text-bold mt-2">Lorem Ipsum</h5>
                        <p className="text-second">This card has a regular title and short paragraph of text below it.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 px-4 mb-4">
                        <div className="card-dotted card-padding text-center text-white">
                        <img src="/images/logo/diamond.png"/>
                        <h5 className="text-bold mt-2">Lorem Ipsum</h5>
                        <p className="text-second">This card has a regular title and short paragraph of text below it.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 px-4 mb-4">
                        <div className="card-dotted card-padding text-center text-white">
                        <img src="/images/logo/diamond.png"/>
                        <h5 className="text-bold mt-2">Lorem Ipsum</h5>
                        <p className="text-second">This card has a regular title and short paragraph of text below it.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 px-4 mb-4">
                        <div className="card-dotted card-padding text-center text-white">
                        <img src="/images/logo/diamond.png"/>
                        <h5 className="text-bold mt-2">Lorem Ipsum</h5>
                        <p className="text-second">This card has a regular title and short paragraph of text below it.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 px-4 mb-4">
                        <div className="card-dotted card-padding text-center text-white">
                        <img src="/images/logo/diamond.png"/>
                        <h5 className="text-bold mt-2">Lorem Ipsum</h5>
                        <p className="text-second">This card has a regular title and short paragraph of text below it.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 px-4 mb-4">
                        <div className="card-dotted card-padding text-center text-white">
                        <img src="/images/logo/diamond.png"/>
                        <h5 className="text-bold mt-2">Lorem Ipsum</h5>
                        <p className="text-second">This card has a regular title and short paragraph of text below it.</p>
                        </div>
                    </div>

                    
                    </div>
                
                </div>
                </div>
            </div>
            </div>
            
        </div>
        <div className="section-padding bg-orange text-white">
            <div className="container">
            <div className="title-pannel text-center">
                <h1 className="display-5 fw-bold lh-1 ">Testinomi Kami</h1>
                
            </div>
            <div className="">
                <div className="row">
                <div className="col-lg-4 col-12 px-4 mb-4">
                    <div className="mt-3">
                    <p className="text-second">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae felis eget diam suscipit pharetra. Praesent eu orci viverra, vulputate neque vitae, egestas dolor."</p>
                    </div>
                    <div className="d-flex gap-4 mt-4">
                    <img className="img-profile-testi" src="/images/users/user.png"/>
                    <div>
                        <label className="text-bold">John Doe</label>
                        <p className="text-second text-smaller">Reebook</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12 px-4 mb-4">
                    <div className="mt-3">
                    <p className="text-second">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae felis eget diam suscipit pharetra. Praesent eu orci viverra, vulputate neque vitae, egestas dolor."</p>
                    </div>
                    <div className="d-flex gap-4 mt-4">
                    <img className="img-profile-testi" src="/images/users/user.png"/>
                    <div>
                        <label className="text-bold">John Doe</label>
                        <p className="text-second text-smaller">Reebook</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-12 px-4 mb-4">
                    <div className="mt-3">
                    <p className="text-second">"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas vitae felis eget diam suscipit pharetra. Praesent eu orci viverra, vulputate neque vitae, egestas dolor."</p>
                    </div>
                    <div className="d-flex gap-4 mt-4">
                    <img className="img-profile-testi" src="/images/users/user.png"/>
                    <div>
                        <label className="text-bold">John Doe</label>
                        <p className="text-second text-smaller">Reebook</p>
                    </div>
                    </div>
                </div>
                
                </div>
            
            </div>
            </div>
            
        </div>

        <div className="">
            <div className="row">
            <div className="col-lg-7 col-12 bg-cream" style={{padding:'10%'}}>
                <div className='text-center'>
                <img src="/images/logo/logo-hattori-indo.png" style={{maxHeight:'48px',marginBottom:'16px'}}/>
               
                <p class="lead mb-4">Jl.Raya Majalaya Rancaekek No.389, Solokanjeruk, Kec.Solokanjeruk, Kabupaten Bandung, Jawa Barat 45364</p>
                <p class="lead">
                Email: <span style={{fontWeight:'600'}}>hattori.indonesia@gmail.com</span>
                <br/>
                Telepon: <span style={{fontWeight:'600'}}>(022)5954325</span>
                </p>
                </div>
            </div>
            <div className="col-lg-5 col-12 " style={{padding:'10%'}}>
                <h1 className="display-5 fw-bold lh-1 mb-3" style={{display:'none'}}>Lorem Ipsum</h1>
                <div >
                <form className="">
                    <div className="mb-3">
                    <label>Name</label>
                    <input type="text" className="form-control" value={quoteName}  name="quoteName"  onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                    <label>Email</label>
                    <input type="text" className="form-control" value={quoteEmail}  name="quoteEmail"   onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                    <label>Company</label>
                    <input type="text" className="form-control" placeholder="" value={quoteCompany}  name="quoteCompany"  onChange={handleChange}/>
                    </div>
                    <div className="mb-3">
                    <label>Message</label>
                    <textarea type="text" className="form-control" placeholder=""   onChange={handleChange}  name="quoteMessage">{quoteMessage}</textarea>
                    </div>
                    <div className='mt-4'>
                        <a href='mailto:hattori.indonesia@gmail.com?subject=Web Quote&body=The Tips and Tricks section is great
&cc=anotheremailaddress@anotherdomain.com
&bcc=onemore@anotherdomain.com'><button className='btn btn-outline-primary'><i className='fa fa-envelope'></i> Send Me Quote </button></a>
                    </div>
                </form>
                
                </div>
            </div>
            </div>
        </div>
        </>
    )
}

export default Home;
