import React from "react";
class Footer extends React.Component {
  render() {
    return(
        <>
        <footer className="bg-dark text-white">
            <div className="footer-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 px-4 mb-4">
                        <div className="d-none d-xl-flex d-lg-flex d-md-none flex-wrap">
                            <img style={{height:'48px'}} src="/images/logo/logo-hattori-512.png" alt="logo-footer"/>
                        </div>

                        <div className="d-flex d-lg-none d-xl-none d-md-flex d-sm-flex flex-wrap">
                            <img style={{height:'48px'}} src="/images/logo/logo-hattori-ht.png" alt="logo-footer"/>
                        </div>

                        <div className="mt-4">
                            <p className="text-second">Solusi kebutuhan bahan Kain & Material Industri dan Usaha Anda. Hattori menyediakan banyak pilihan kain dan bahan sintesis untuk kebutuhan proses manufaktur dan produksi alat industri.</p>
                        </div>
                        
                        </div>
                        <div className="col-lg-2 col-12 px-4 mb-4">
                        <h4 className="fw-bold" style={{
                        paddingBottom:'5%',
                        }}>Products</h4>
                        <ul style={{listStyle:'none',padding:'0',margin:'0'}} className="text-second">
                            <a className="footer-link" href="/products/#weaving"><li className="mb-2">weaving</li></a>
                            <a className="footer-link" href="/products/#knitting"><li className="mb-2">knitting</li></a>
                            <a className="footer-link" href="/products/#garment"><li className="mb-2">garment</li></a>
                            <a className="footer-link" href="/products/#buffing"><li className="mb-2">buffing</li></a>
                        </ul>
                        </div>
                        <div className="col-lg-2 col-12 px-4 mb-4">
                        <h4 className="fw-bold" style={{
                        paddingBottom:'5%',
                        }}>Company</h4>
                        <ul style={{listStyle:'none',padding:'0',margin:'0'}} className="text-second">
                            <a className="footer-link" href="/about"><li className="mb-2">About</li></a>
                            <a className="footer-link" href="/products"><li className="mb-2">Product</li></a>
                            <a className="footer-link" href="#"><li className="mb-2">News</li></a>
                            <a className="footer-link" href="/contact"><li className="mb-2">Contact</li></a>
                        </ul>
                        </div>
                        <div className="col-lg-4 col-12 px-4 mb-4">
                            <h4 className="fw-bold" style={{
                            paddingBottom:'5%',
                            }}>Address</h4>
                            <p className="text-second" >Jl. Raya Majalaya - Rancaekek No.389, Solokanjeruk, Kec. Solokanjeruk,Kabupaten Bandung,<br/>Jawa Barat 45364</p>
                        </div>
                        
                    </div>
                </div>
                
            </div>
            <hr/>
            <div className="text-center text-second py-5 px-4">
            Copyright © 2022 by Hattori Indonesia Allright Reserved
            </div>
        </footer>
    </>
    );
  }
}


export default Footer;