import React from "react";
class Header extends React.Component {
  render() {
    return(
    <>
        <header className="p-3">
            <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-none d-xl-flex d-lg-flex d-md-none flex-wrap">
                        <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                            <img style={{height:'34px'}} src="/images/logo/logo-hattori-indo.png" alt="logo-hattori"/>
                        </a>
                    </div>

                    <div className="d-flex d-lg-none d-xl-none d-md-flex d-sm-flex flex-wrap">
                        <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                            <img style={{height:'34px'}} src="/images/logo/logo-hattori-ht.png" alt="logo-hattori"/>
                        </a>
                    </div>
                    <div className="d-none d-xl-flex d-lg-flex d-md-none flex-wrap">
                        <div className="text-end me-lg-3">
                        <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                            <li><a href="/" className="nav-link px-2 text-secondary px-3">HOME</a></li>
                            <li><a href="/about" className="nav-link px-2 text-black px-3">ABOUT</a></li>
                            <li><a href="/products" className="nav-link px-2 text-black px-3">PRODUCTS</a></li>
                            <li><a href="/contact" className="nav-link px-2 text-black px-3">CONTACT</a></li>
                        </ul>
                        </div>
                        <form className="col-12 col-lg-auto mb-3 mb-lg-0">
                        <input type="search" className="form-control form-control-dark" placeholder="Search..." aria-label="Search"/>
                        </form>
                        
                    </div>

                    <div className="d-flex d-lg-none d-xl-none d-md-flex d-sm-flex flex-wrap ">
                        <div className="dropdown text-end">
                        <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="/images/menu.png" alt="mdo" width="32" height="32" className="rounded-circle"/>
                        </a>
                        <ul className="dropdown-menu text-small" aria-labelledby="dropdownUser1">
                            <li><a className="dropdown-item" href="/">Home</a></li>
                            <li><a className="dropdown-item" href="/about">About</a></li>
                            <li><a className="dropdown-item" href="/products">Products</a></li>
                            <li><a className="dropdown-item" href="/contact">Contact</a></li>
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
    );
  }
}


export default Header;

